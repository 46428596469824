import { GetServerSideProps } from 'next'
import Layout from '../components/layouts/Layout'
import LoginForm, { LoginData } from '../components/auth/LoginForm'
import { useState } from 'react'
import withoutAuth from '../lib/withoutAuth'
import FooterLinks from '../components/footer/FooterLinks'
import indexStyles from '../styles/modules/Index.module.scss'

const signin = async (email, password): Promise<any> => {
  const response = await fetch(`/api/v1/auth/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({
      email,
      password,
    }),
  })

  const data = await response.json()

  return data
}

interface LoginProps {
  errorMessage?: string
  redirect: Optional<string>
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  return withoutAuth(context)(async () => {
    const error = context.query?.error
    const redirect = context.query?.redirect || null
    let errorMessage = ''
    if (error === 'token_expired') {
      errorMessage =
        'The link used to reset your password is invalid or has expired. Please request a new one.'
    }

    return {
      props: {
        errorMessage,
        redirect,
      },
    }
  })
}

export default function Login(props: LoginProps): JSX.Element {
  const [loginError, setLoginError] = useState(props.errorMessage)

  const requestLogin = async (data: LoginData) => {
    try {
      const result = await signin(data.email, data.password)
      if (!result.error) {
        window.location.href = props.redirect || '/'
      } else {
        setLoginError(result.error)
      }
    } catch (error) {
      setLoginError('Login failed.')
    }
  }

  return (
    <Layout>
      <div className={`${indexStyles.container} card-container get-support`}>
        <div className={`${indexStyles.content} track-request`}>
          <h1 id="trackrequests">Log In</h1>
          <LoginForm loginError={loginError} loginRequest={requestLogin} />
        </div>
      </div>
      <FooterLinks />
    </Layout>
  )
}
