import React from 'react'
import Script from 'next/script'

interface Userback {
  userName?: string
  userEmail?: string
  customButton?: boolean
}

const Userback: React.FC<Userback> = ({
  userEmail,
  userName,
  customButton,
}) => {
  const email = userEmail || ''
  const name = userName || ''
  let customConfig = ``
  if (customButton) {
    customConfig = `
      var body = document.body;
      window.Userback.on_open = () => {
        body.classList.add("open");
      };
      window.Userback.on_close = () => {
        body.classList.remove("open");
      };
      Userback.before_send = function() {
        body.classList.remove("open");
      };
    `
  }

  return (
    <>
      <Script id="user-back" strategy="lazyOnload">
        {`
        window.Userback = window.Userback || {};
        ${customConfig}
        Userback.access_token = "32636|59886|fNDPDbiTeNkA7ELMFXQxLdHp2";
        (function (d) {
          var s = d.createElement("script");
          s.async = true;
          s.src = "https://static.userback.io/widget/v1.js";
          (d.head || d.body).appendChild(s);
        })(document);
          window.Userback.name = "${name}";
          window.Userback.email = "${email}";
      `}
      </Script>
    </>
  )
}

export { Userback }
