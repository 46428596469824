import IconNetwork from 'pong-react-components/assets/images/icons/products/icon-companynetwork.svg'
import IconConnect from 'pong-react-components/assets/images/icons/products/icon-product-connect.svg'
import IconPrism from 'pong-react-components/assets/images/icons/products/icon-product-prism.svg'
import IconSpaces from 'pong-react-components/assets/images/icons/products/icon-product-spaces.svg'
import IconStudio from 'pong-react-components/assets/images/icons/products/icon-product-studio.svg'
import IconChat from 'pong-react-components/assets/images/icons/products/icon-product-chat.svg'
import IconDatagraph from 'pong-react-components/assets/images/icons/products/icon-product-datagraph.svg'

export const getIcon = (bundleName: string) => {
  switch (bundleName) {
    case 'apps':
      return IconNetwork
    case 'connect':
      return IconConnect
    case 'enterprise':
      return IconPrism
    case 'spaces':
    case 'space':
      return IconSpaces
    case 'studio':
      return IconStudio
    case 'chat':
      return IconChat
    case 'datagraph':
      return IconDatagraph
  }
}

export const getTitle = (bundleName: string) => {
  switch (bundleName) {
    case 'apps':
      return 'Pong Network'
    case 'connect':
      return 'Connect'
    case 'enterprise':
      return 'Pong Platform'
    case 'spaces':
    case 'space':
      return 'Pong Prism'
    case 'studio':
      return 'Pong Studio'
    case 'chat':
      return 'Pong Circles'
    case 'datagraph':
      return 'CBI Datagraph'
  }
}
