import styles from '../../styles/modules/Footer.module.scss'

const FooterLinks: React.FC<Record<string, unknown>> = () => {
  const year = new Date().getFullYear()
  return (
    <div className={styles.footer}>
      <span className={styles.copyright}>
        Copyright © {year} Pong Labs, LLC All rights reserved.
      </span>
      <a
        href="https://www.pong.ai/privacy"
        target="_blank"
        rel="noreferrer"
        className={styles.link}
      >
        Privacy Policy
      </a>
    </div>
  )
}

export default FooterLinks
